<template>
  <transition name="fade" appear>
    <div class="home">
      <el-row class="search">
        <el-col :span="21"
          ><input
            type="text"
            placeholder="请输入"
            class="et_search"
            v-model="keyWords"
            @keyup.enter="getPlaygroundList()"
        /></el-col>
        <el-col :span="3"
          ><button class="search_text" @click="getPlaygroundList()">
            搜索
          </button></el-col
        >
      </el-row>
      <el-row class="title">
        <section class="tag flex align-center">
          <div class="view" @click="type_left">
            <i class="el-icon-arrow-left pointer" style="font-size: 18px" />
          </div>
          <div class="tagBox flex align-center">
            <template v-for="(item, index) in playgroundTypeListShow">
              <div
                :key="index"
                :class="[
                  'tagItem',
                  'pointer',
                  item.playgroundTypeCode == chooseType ? 'activeTag' : '',
                ]"
                @click="change(index)"
              >
                {{ item.playgroundTypeName }}
              </div>
            </template>
          </div>

          <div class="view" @click="type_right" >
            <i class="el-icon-arrow-right pointer" style="font-size: 18px" />
          </div>
        </section>

        <!-- <el-col :span="2"><div class="f18">分类：</div></el-col>
        <el-col :span="22">
          <section class="navBox">
            <template v-for="(item, index) in playgroundTypeList">
              <div class="nav_item_type" :key="index" @click="change(index)">
                <div :class="[typeIndex == index ? 'type_true' : 'type_false']">{{ item.playgroundTypeName }}</div>
              </div>
            </template>
          </section>
        </el-col> -->
      </el-row>

      <section class="navBox2">
        <div class="show" v-show="!show">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <template v-for="(item, index) in playgroundList">
          <div
            class="nav_item_festival"
            :key="index"
            @click="goFmDetail(index)"
          >
            <div class="navItem_img_festival hoverBox1">
              <el-image
                :src="item.logo"
                fit="fill"
                style="height: 100%; width: 100%"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="navItem_text__festival hoverText">
              {{ item.playgroundName }}
            </div>
          </div>
        </template>
      </section>

      <el-pagination
        v-show="show"
        background=""
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :current-page.sync="currPage"
      >
      </el-pagination>
      <!-- <div class="point"><el-pagination background="" layout="prev, pager, next" :total="1000"> </el-pagination></div> -->
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "kidsky",
  data() {
    return {
      title: "公共管理学课程教学大纲",
      time: "2022年2月14日",
      point: "0",
      keyWords: "",
      //数据总量
      total: 0,
      //单页数量
      pageSize: 12,
      //当前页数
      currPage: 1,
       //切换tab
     currTabPage: 1,
      //分类下标
      typeIndex: 0,
      filterUrl: "http://banktrain.pluss.cn/upload/file_202202240916941.pdf",
      //课程分类
      playgroundTypeList: [],
      //课程列表
      playgroundList: [],
      //个人信息
      userInfo: {},
      //是否有数据
      show: false,
      //分类取值
      end: 0,
      playgroundTypeListShow: [],
      //选择分类
      chooseType: "",
      index:'',
    };
  },
  methods: {
    // 修改当前页
    handleCurrentChange(newCurr) {
      this.currPage = newCurr;
      this.getPlaygroundList();
    },

    //左页
    type_left() {
      if (this.end > 0) {
        this.playgroundTypeListShow = this.playgroundTypeList.slice(
          this.end - 5,
          this.end
        );
        this.end = this.end - 5;
        this.currTabPage-=1
      } else {
        // this.$message.error("这是第一页");
        this.$message({
                    message: "这是第一页",
                    type: "error",
                    offset:'400'
                  });

      }
      //  this.typeIndex=this.end+this.currTabPage*5
      this.typeIndex=0
        this.getPlaygroundList();
        this.change(this.typeIndex)
    },

    //右页
    type_right() {
      if (this.end + 5 < this.playgroundTypeList.length) {
        this.end = this.end + 5;
        this.playgroundTypeListShow = this.playgroundTypeList.slice(
          this.end,
          this.end + 5
        );  
        this.currTabPage+=1
      } else {
        // this.$message.error("已经到底了");
        this.$message({
                    message: "已经到底了",
                    type: "error",
                    offset:'400'
                  });
      }
        this.typeIndex=0
        this.getPlaygroundList(); 
        this.change(this.typeIndex)
    },
    //激活验证码
    setActivationCode(code) {
      var getDate = {};
      getDate.memberCode = this.userInfo.memberCode;
      getDate.activationCode = code;
      this.$api.lesson.setActivationCode(getDate).then((res) => {
        if ((res.data.code = 200)) {
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.userInfo=res.data.data
        }
      });
    },

    //获取分类
    getPlaygroundTypeList() {
      var getDate = {};
      var condition = {};
      let a = sessionStorage.getItem("userInfo")
      if(a==null){
        condition.promptCode = "rz"
      }else{
        let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
        if(promptCode == "kj" || promptCode == "KJ"){
          condition.promptCode = "kj"
        }else if(promptCode == "rz" || promptCode == "RZ"){
          condition.promptCode = "rz"
        }else{
          return
        }
      }
      getDate.pageSize = 100;
      getDate.currPage = this.currPage;
      getDate.condition = condition;
      // console.log("getData=>",getDate)
      this.$api.lesson.getPlaygroundTypeList(getDate).then((res) => {
        if ((res.data.code = 200)) {
          this.playgroundTypeList = res.data.data.data;
          // console.log("===>playgroundTypeList===>res",res)
          this.chooseType = this.playgroundTypeList[this.typeIndex].playgroundTypeCode;
          this.playgroundTypeListShow = this.playgroundTypeList.slice(
            this.end,
            this.end + 5
          );
          this.getPlaygroundList();
        }
      });
    },
    //获取课程列表
    getPlaygroundList() {
      var getDate = {};
      var condition = {};
      // condition.playgroundTypeCode = this.playgroundTypeList[this.typeIndex+(this.currTabPage-1)*5].playgroundTypeCode;
      condition.playgroundTypeCode = this.playgroundTypeList[this.typeIndex].playgroundTypeCode
      if(this.currTabPage==1){
         condition.playgroundTypeCode = this.playgroundTypeList[this.typeIndex].playgroundTypeCode
      }else{
         condition.playgroundTypeCode = this.playgroundTypeList[this.typeIndex+(this.currTabPage-1)*5].playgroundTypeCode;
      }
      condition.playgroundName = this.keyWords;
      getDate.condition = condition;
      getDate.pageSize = this.pageSize;
      getDate.currPage = this.currPage;
      this.$api.lesson.getPlaygroundList(getDate).then((res) => {
        if ((res.data.code = 200)) {
          this.playgroundList = res.data.data.data;
          this.total = res.data.data.data.length; 
          if (this.total >0) {
            this.show = true;
          } else {
            
            this.show = false;
          }
        }
      });
    },

    //切换分类
    change(index) {
      this.typeIndex = index;
      this.chooseType = this.playgroundTypeListShow[this.typeIndex].playgroundTypeCode;
      this.getPlaygroundList();
    },

    // 前往课程详情
    goFmDetail(index) {
      // //当前时间
      // var currentTime = Date.parse(new Date());
      // console.log("==>", currentTime);
      // if (this.userInfo.expirationTime) {
      // // 此处待开放
      //   if (currentTime - this.userInfo.expirationTime >= 0) {
      //     this.open();
      //   } else {
        sessionStorage.setItem('typeIndex',this.typeIndex)
          this.$router.push({
            path: "/kidskyDetail",
            query: {
              playgroundCode: this.playgroundList[index].playgroundCode,
              logo:this.playgroundList[index].logo,
            },
          });
      //   }
      // } else {
      //   this.open();
      // }
      
    },
    //是否激活
    open() {
      this.$prompt("您还没有激活此功能，请输入激活码后使用", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        title: "",
        inputPlaceholder: "请输入激活码",
        showCancelButton: "false",
        center: true,
      })
        .then(({ value }) => {
          // this.setActivationCode(value);
          this.setActivationCode(value);
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "您还没有激活",
            offset:'400',
          });
        });
    },
   
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (sessionStorage.getItem('typeIndex')==null) {
       this.typeIndex= this.$route.query.index
    } else {
       this.typeIndex=sessionStorage.getItem('typeIndex');
         sessionStorage.removeItem('typeIndex')
    }
    this.getPlaygroundTypeList();
  },
   
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 40px;
  padding: 0 50px;
  background-color: white;
  .search {
    border: 1px solid #fab300;
    border-radius: 6px;
    display: flex;
    height: 53px;
    align-items: center;
    .et_search {
      outline: none;
      font-size: 18px;
      width: 100%;
      padding-left: 30px;
      align-items: flex-start;
      border: none;
    }
    .search_text {
      height: 53px;
      background-color: #fab300;
      border-radius: 6px;
      align-items: center;
      width: 100%;
      border: none;
      line-height: 53px;
      vertical-align: middle;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .title {
    margin-top: 20px;
    .tag {
      margin: 26px 0 21px;
      .tagBox {
        width: 100%;
        .tagItem {
          width: 18%;
          margin: 0 1%;
          font-size: 21px;
        }
        .activeTag {
          color: #f8b229;
        }
      }
    }
  }
  .f18 {
    font-size: 20px;
  }
  .navBox {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-flow: wrap;
    .nav_item_type {
      display: flex;
      .type_true {
        color: #fab300;
        padding-left: 15px;
        font-size: 20px;
      }
      .type_false {
        font-size: 20px;
        margin-left: 15px;
      }
    }
  }
  .navBox2 {
    display: flex;
    justify-content: flex-start;
    min-height: 530px;
    align-content: flex-start;
    flex-flow: wrap;
    .show {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .nav_item_festival {
      display: flex;
      width: 25%;
      margin: 20px 0 0 0;
      flex-direction: column;
      align-items: center;
      .navItem_img_festival {
        height: 118px;
        width: 118px;
        border-radius: 6px;
        overflow: hidden;
      }
     .navItem_img_festival:hover{
       cursor: pointer;
      }
      .navItem_text__festival {
        margin-top: 9px;
        font-size: 14px;
        color: #000000ff;
      }
    }
    
  }

  .point {
    text-align: right;
    margin: 20px;
    margin-right: 40px;
  }
}
</style>
